import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import ClassIcon from "@mui/icons-material/Class";
import StorageIcon from "@mui/icons-material/Storage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { arabicTitles } from "./Utils";
import { Typography } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GradeIcon from "@mui/icons-material/Grade";

export default function TemporaryDrawerTeacher() {
  const role = localStorage.getItem("role");

  const [title, setTitle] = React.useState(" جدول الحصص");
  const [open, setOpen] = React.useState(false);

  const handleTitle = (string: string) => {
    setTitle(string);
    localStorage.setItem("DirectorDrawer", string);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  React.useEffect(() => {
    const data = localStorage.getItem("DirectorDrawer");
    setTitle(data ?? " جدول الحصص");
  }, []);

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <NavLink
          to="/"
          onClick={() => handleTitle(arabicTitles["/"])}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ClassIcon />
              </ListItemIcon>
              <ListItemText primary={" جدول الحصص"} />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          to="marks"
          onClick={() => handleTitle(arabicTitles["marks"])}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary={" النقط"} />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          to={`${role === "teacher" ? "create-timetable" : "homework"}`}
          onClick={() =>
            handleTitle(
              arabicTitles[
                `${role === "teacher" ? "create-timetable" : "homework"}`
              ],
            )
          }
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  role === "teacher" ? "إستعمال الزمن" : " الواجبات المنزلية"
                }
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        {role === "teacher" && (
          <>
            <NavLink
              to="quarterly-grade"
              onClick={() => handleTitle(arabicTitles["quarterly-grade"])}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <GradeIcon />
                  </ListItemIcon>
                  <ListItemText primary={"الدورات"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              to="trimester-assessment"
              onClick={() => handleTitle(arabicTitles["trimester-assessment"])}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={"الفروض"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </>
        )}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div style={{ marginTop: "10px" }}>
      <Button onClick={toggleDrawer(true)}>
        <Typography variant="h5">{title}</Typography>
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
