import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchCGT = createAsyncThunk<
  void,
  Inter.patchCGT,
  { rejectValue: string }
>("cgt/patchCGT", async (data: Inter.patchCGT, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_PATCH_CGT}/${data.cgt_id}`;
    const response = await appJSON.patch<void>(URL, data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const patchCGT = createSlice({
  name: "cgtPatch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatchCGT.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchPatchCGT.rejected, (state) => {
      return state;
    });
  },
});

export default patchCGT.reducer;
