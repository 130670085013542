import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import { appJSON } from "../../utils/AxiosConfig";

const initialState: Array<Inter.getAssessment> = [
  {
    title: "",
    course_id: -1,
    course_name: "",
    id: 0,
  },
];

export const fetchGetAssessment = createAsyncThunk<
  Inter.getAssessment[],
  Inter.queryAssessment,
  { rejectValue: string }
>(
  "assessment/getAssessment",
  async (data: Inter.queryAssessment, { rejectWithValue }) => {
    const params = {
      ...(data.course_id_filter && { course_id_filter: data.course_id_filter }),
    };

    try {
      const URL = `${process.env.REACT_APP_GET_ASSESSMENT}`;
      const response = await appJSON.get<Inter.getAssessment[]>(URL, {
        params,
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const getAssessment = createSlice({
  name: "get_assessment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetAssessment.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchGetAssessment.rejected, (state) => {
      return state;
    });
  },
});

export default getAssessment.reducer;
