import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchMarks = createAsyncThunk<
  void,
  Inter.patchMarks,
  { rejectValue: string }
>("marks/patchMarks", async (data: Inter.patchMarks, { rejectWithValue }) => {
  try {
    const marks = {
      mark: data.mark !== "" ? data.mark : null,
    };

    const URL = `${process.env.REACT_APP_MARKS_CRUD}${data.id}`;
    const response = await appJSON.patch<void>(URL, marks);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const patchMarks = createSlice({
  name: "patchMarks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatchMarks.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchPatchMarks.rejected, (state) => {
      return state;
    });
  },
});

export default patchMarks.reducer;
