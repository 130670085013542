import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchTeacherHomewk = createAsyncThunk<
  void,
  Inter.patchTeachHomework,
  { rejectValue: string }
>(
  "teacher/patchTeacher",
  async (data: Inter.patchTeachHomework, { rejectWithValue }) => {
    try {
      const params = {
        homework: data.homework,
        theme: data.theme,
        description: data.description,
      };

      const URL = `${process.env.REACT_APP_PATCH_TEACHER_HOMEWORK}${data.id}`;
      const response = await appJSON.patch<void>(URL, params);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const patchTeacherHomewk = createSlice({
  name: "teacherPatchHomewk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatchTeacherHomewk.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchPatchTeacherHomewk.rejected, (state) => {
      return state;
    });
  },
});

export default patchTeacherHomewk.reducer;
