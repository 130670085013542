import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchDeleteCGT = createAsyncThunk<
  void,
  string,
  { rejectValue: string }
>("cgt/deleteGGT", async (data: string, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_DELETE_CGT}${data}`;
    const response = await appJSON.delete<void>(URL);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const deleteCGT = createSlice({
  name: "CGTDelete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteCGT.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchDeleteCGT.rejected, (state) => {
      return state;
    });
  },
});

export default deleteCGT.reducer;
