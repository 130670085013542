import React from "react";
import styles from "../Auth.module.scss";
import TextField from "@mui/material/TextField";

interface Props {
  dataLogin: string;
  dataPassword: string;
  handleLogin: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Inputs: React.FC<Props> = ({
  dataLogin,
  dataPassword,
  handleLogin,
  handlePassword,
}) => {
  return (
    <div className={styles.signin}>
      <TextField
        id="login"
        label="تسجيل الدخول"
        value={dataLogin}
        className={styles.signinInput}
        onChange={handleLogin}
        variant="outlined"
        fullWidth
      />
      <TextField
        type="password"
        id="password"
        className={styles.signinInput}
        value={dataPassword}
        onChange={handlePassword}
        label="كلمة المرور"
        variant="outlined"
        fullWidth
      />
    </div>
  );
};

export default Inputs;
