import axios from "axios";

axios.defaults.withCredentials = true;

// eslint-disable-next-line no-undef
const baseURL = `${process.env.REACT_APP_BASE_URL}`;

const app = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 50000,
  headers: {
    accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export const appJSON = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 30000,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const appFiles = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 15000,
  headers: {
    accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

app.interceptors.request.use((config) => {
  return config;
});

/* 
  The below is required if you want your API to return 
  server message errors. Otherwise, you'll just get 
  generic status errors.

  If you use the interceptor below, then make sure you 
  return an "err" (or whatever you decide to name it) message 
  from your express route: 
  
  res.status(404).json({ err: "You are not authorized to do that." })

*/
app.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // eslint-disable-next-line no-undef
      return app
        .post(`${process.env.REACT_APP_AUTH}`, {
          grant_type: "refresh_token",
        })
        .then((res) => {
          if (res.status === 201) {
            return app(originalRequest);
          } else if (res.status === 401) {
            localStorage.removeItem("role");
            return Promise.reject(new Error(`Unauthorized access token`));
          }
        });
    }
    return Promise.reject(error);
  },
);

appJSON.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // eslint-disable-next-line no-undef
      return app
        .post(`${process.env.REACT_APP_AUTH}`, {
          grant_type: "refresh_token",
        })
        .then((res) => {
          if (res.status === 201) {
            return app(originalRequest);
          } else if (res.status === 401) {
            localStorage.removeItem("role");
            return Promise.reject(new Error(`Unauthorized access token`));
          }
        });
    }
    return Promise.reject(error);
  },
);

appFiles.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // eslint-disable-next-line no-undef
      return app
        .post(`${process.env.REACT_APP_AUTH}`, {
          grant_type: "refresh_token",
        })
        .then((res) => {
          if (res.status === 201) {
            return app(originalRequest);
          } else if (res.status === 401) {
            localStorage.removeItem("role");
            return Promise.reject(new Error(`Unauthorized access token`));
          }
        });
    }
    return Promise.reject(error);
  },
);

export default app;
