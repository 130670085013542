import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchCourse = createAsyncThunk<void, Inter.patchCourse, { rejectValue: string }>(
    "course/patchCourse", 
    async (data: Inter.patchCourse, { rejectWithValue }) => {
    try {
        const URL = `${process.env.REACT_APP_PATCH_COURSE}/${data.id}`;
        const response = await appJSON.patch<void>(URL, data);
        return response.data;
    } catch (error: unknown) {
        if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
        }
        throw error;
    }
});

const patchCourse = createSlice({
  name: "coursePatch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatchCourse.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchPatchCourse.rejected, (state) => {
      return state;
    });
  },
});

export default patchCourse.reducer;
