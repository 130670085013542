import React from "react";
import styles from "../Header.module.scss";
import { NavLink } from "react-router-dom";

const Info: React.FC = () => {
  const logout = (): void => {
    localStorage.clear();
    sessionStorage.clear();
  };

  const fullName = localStorage.getItem("full_name");

  return (
    <div className={styles.headerInfo}>
      <div className={styles.brand}>
        <p className={styles.brandInfo}>Tilmidi</p>
      </div>
      <div className={styles.info}>
        <p className={styles.infoDate}>مرحبًا</p>
        <div className={styles.studyInfo}>
          <p className={styles.studyDate}>2025-2024 السنة الدراسية</p>
          <p className={`${styles.studyAccount} ${styles.studyAcc}`}>
            {fullName}
          </p>
          <NavLink
            to="/auth"
            onClick={() => logout()}
            className={`${styles.studyAccount} ${styles.studyLogOut}`}
          >
            تسجيل الخروج{" "}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Info;
