import React, { useEffect } from "react";
import Header from "./header/Header";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { isDirector } from "../../redux/slices/directorSlice";
import { Suspense } from "react";
import ReactLoading from "react-loading";

const MainLayout: React.FC = () => {
  const role = localStorage.getItem("role");
  const dispatch = useAppDispatch();

  const navigate: NavigateFunction = useNavigate();
  const auth = useAppSelector((state) => state.auth);

  if (role === "director") {
    dispatch(isDirector(true));
  }
  const refresh = sessionStorage.getItem("auth");
  useEffect(() => {
    if (!refresh || refresh === "false") {
      return navigate("/auth");
    }
  }, [auth]);

  return (
    <>
      <Header />
      <Suspense
        fallback={
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              height: "100vh",
              backgroundColor: "white",
            }}
          >
            <div style={{ margin: "0 auto", width: "64px" }}>
              <ReactLoading type="spin" color="#000000" />
            </div>
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
};

export default MainLayout;
