import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchDeleteTeacher = createAsyncThunk<
  void,
  string,
  { rejectValue: string }
>("teacher/deleteTeacher", async (data: string, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_DELETE_TEACHER}${data}`;
    const response = await appJSON.delete<void>(URL);

    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const deleteTeacher = createSlice({
  name: "teacherDelete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteTeacher.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchDeleteTeacher.rejected, (state) => {
      return state;
    });
  },
});

export default deleteTeacher.reducer;
