import * as Inter from "../../interfaces/Interfaces";
import { createSlice } from "@reduxjs/toolkit";

const initialState: Inter.ITeacher = {};

const teacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {
    isTeacher: (state, action) => {
      state.isTeacher = action.payload;
    },
  },
});

export const { isTeacher } = teacherSlice.actions;

export default teacherSlice.reducer;
