import React from "react";
import styles from "./Header.module.scss";
import Info from "./info/Info";
import TemporaryDrawerDirector from "./toggle-menu/TemporaryDrawerDirector";
import TemporaryDrawerTeacher from "./toggle-menu/TemporaryDrawerTeacher";

const Header: React.FC = () => {
  const role = localStorage.getItem("role");

  return (
    <header className={styles.header}>
      <Info />
      {role === "director" ? (
        <div className={styles.navigation}>
          <TemporaryDrawerDirector />
        </div>
      ) : (
        <div className={styles.navigation}>
          <TemporaryDrawerTeacher />
        </div>
      )}
    </header>
  );
};

export default Header;
