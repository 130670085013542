import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchStudent = createAsyncThunk<
  void,
  Inter.patchStudent,
  { rejectValue: string }
>(
  "student/patchStudent",
  async (data: Inter.patchStudent, { rejectWithValue }) => {
    try {
      const URL = `${process.env.REACT_APP_PATCH_STUDENT}${data.id}`;
      const response = await appJSON.patch<void>(URL, data);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const patchStudent = createSlice({
  name: "studentPatch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatchStudent.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchPatchStudent.rejected, (state) => {
      return state;
    });
  },
});

export default patchStudent.reducer;
