import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import app from "../../utils/AxiosConfig";
import axios from "axios";

const initialState: Inter.ICredentials = {
  credentials: {
    users: [
      {
        name: "",
        surname: "",
        username: "",
        password: "",
        group_id: 0,
        group_name: "",
        id: 0,
      },
    ],
  },
  count: 0,
};

export const fetchGetCredentials = createAsyncThunk<
  Inter.ICredentials,
  Inter.sendCredentials,
  { rejectValue: string }
>(
  "credentials/getCredentials",
  async (data: Inter.sendCredentials, { rejectWithValue }) => {
    try {
      const URL = `${process.env.REACT_APP_GET_CREDENTIALS}`;
      const response = await app.get<Inter.ICredentials>(URL, {
        params: {
          role: data.role,
          page_number: data.page_number,
          elements_amount: data.elements_amount,
          ...(data.name_filter && { name_filter: data.name_filter }),
          ...(data.surname_filter && { surname_filter: data.surname_filter }),
          ...(data.group_id && { group_id_filter: data.group_id }),
        },
      });

      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const getCredentialsSlice = createSlice({
  name: "get_credentials",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetCredentials.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchGetCredentials.rejected, (state) => {
      return state;
    });
  },
});

export default getCredentialsSlice.reducer;
