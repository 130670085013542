import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import { appJSON } from "../../utils/AxiosConfig";

const initialState: Inter.getParentsPagination = {
  parents: [
    {
      student_id: -1,
      id: -1,
      student_name: "",
      student_surname: "",
      name: "",
      surname: "",
      phone_number: "",
      group_id: -1,
      group_name: "",
    },
  ],
  count: 0,
};

export const fetchGetParents = createAsyncThunk<
  Inter.getParentsPagination,
  Inter.getParentsQuery,
  { rejectValue: string }
>(
  "parents/getParents",
  async (data: Inter.getParentsQuery, { rejectWithValue }) => {
    const params = {
      ...(data.page_number && { page_number: data.page_number }),
      ...(data.elements_amount && { elements_amount: data.elements_amount }),
      ...(data.name_filter && { name_filter: data.name_filter }),
      ...(data.surname_filter && { surname_filter: data.surname_filter }),
      ...(data.group_id && { group_id_filter: data.group_id }),
      ...(data.phone_number && { phone_number_filter: data.phone_number }),
    };

    try {
      const URL = `${process.env.REACT_APP_GET_PARENTS}`;
      const response = await appJSON.get<Inter.getParentsPagination>(URL, {
        params,
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const getParents = createSlice({
  name: "get_parents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetParents.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchGetParents.rejected, (state) => {
      return state;
    });
  },
});

export default getParents.reducer;
