import * as Inter from "../../interfaces/Interfaces";
import { createSlice } from "@reduxjs/toolkit";

const initialState: Inter.ITeacher = {};

const directorSlice = createSlice({
  name: "director",
  initialState,
  reducers: {
    isDirector: (state, action) => {
      state.isDirector = action.payload;
    },
  },
});

export const { isDirector } = directorSlice.actions;

export default directorSlice.reducer;
